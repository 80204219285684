import { Component, Input, OnInit, effect } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationLink } from '../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  // ? INPUTS
  @Input() collapsed: boolean;

  // ? VARIABLES
  privileges: string[] = []
  authorizedUrlsList: any[] = []
  userName: string = null
  userEmail: string = null

  // ? OBSERVABLES
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
  userVisible$ = this.configService.config$.pipe(map(config => config.sidenav.user.visible));
  searchVisible$ = this.configService.config$.pipe(map(config => config.sidenav.search.visible));
  userMenuOpen$: Observable<boolean> = of(false);

  navItems = this.navigationService.items;
  showProjectTitle: boolean;
  constructor(private navigationService: NavigationService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private readonly popoverService: PopoverService,
    private readonly dialog: MatDialog) { }

  ngOnInit() {
    this.layoutService.showProjectTitle$.subscribe(data => this.showProjectTitle = data);
    this.privileges = (localStorage.getItem('privileges')).split(',')
    this.restrictMenus()

    if (localStorage.getItem('userName'))
      this.userName = localStorage.getItem('userName')
    if (localStorage.getItem('userEmail'))
      this.userEmail = localStorage.getItem('userEmail')
  }

  // ? RESTRICT THE SIDE MENUS BASED ON THE ROLES & PRIVILEGES
  restrictMenus() {
    this.navItems = this.navItems.filter((item: any) => this.privileges.includes(item.tag))
    this.navItems.map((item: any) => { this.authorizedUrlsList.push({ path: item.route }) })
    for (let item of this.navItems) {
      if (item.children) {
        item.children = item.children.filter((item: any) => this.privileges.includes(item.tag))
        item.children.map((item: any) => { this.authorizedUrlsList.push({ path: item.route }) })
      }
    }
    localStorage.setItem('urlList', JSON.stringify(this.authorizedUrlsList))
  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
    if (this.collapsed) {
      this.showProjectTitle = true;
      this.layoutService.showSubMenuIcon.set(true); //* THIS IS TO TOGGLE THE VISIBLE OF THE SUB-MENU ICON IN COLLAPSED VIEW
    }
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
    if (this.collapsed) {
      this.showProjectTitle = false;
      this.layoutService.showSubMenuIcon.set(false); //* THIS IS TO TOGGLE THE VISIBLE OF THE SUB-MENU ICON IN COLLAPSED VIEW
    }
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }
}
