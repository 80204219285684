import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { Profile } from './edit-profile.model';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProfileService } from './profile.service';
import { takeUntil } from 'rxjs';
import { UnSubscribe } from 'src/app/shared/classes/un-sub';
import * as moment from 'moment-timezone';
import { UsersService } from 'src/app/pages/users/services/users.service';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent extends UnSubscribe implements OnInit {
  // ? VARIABLES
  isLoading: boolean = false;
  isDisabled: boolean = true
  isRequired: boolean = false

  // ? INJECTS
  dialog = inject(MatDialog)
  authService = inject(AuthService)
  messageService = inject(MessageService)
  profileService = inject(ProfileService)
  commonService = inject(CommonService)
  router = inject(Router)
  userService = inject(UsersService)

  // ? MODELS
  profileModel: Profile = new Profile();

  userName: string = localStorage.getItem('userName') || '';
  trackById = trackById;

  constructor(private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>) {
    super()
  }

  close() {
    this.popoverRef.close();
  }

  // ? OPEN THE CHANGE PASSWORD DIALOG
  openChangePasswordDialog(dialog: any) {
    this.dialog.open(dialog, {
      width: '700px',
      position: {
        top: '100px'
      }
    })
  }

  // ? LOGOUT
  logout() {
    this.authService.logOut()
  }

  ngOnInit() {
  }

  // ? UPDATE PROFILE
  updateProfile() {
    if (this.profileModel.newPassword != this.profileModel.conPassword) {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: "Password and confirm new password don't match" });
      return
    }
    this.isLoading = true
    this.profileModel.tz = moment.tz.guess()
    this.profileModel.email = localStorage.getItem('userEmail')
    this.profileService.updateProfile({ data: this.commonService.encryptData(this.profileModel) }).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const response = this.commonService.decryptData({ data: res })
      if (response.status == 'failure') {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'The old password is incorrect...' });
        this.isLoading = false
        return
      }
      else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Profile updated successfully...' });
        this.isLoading = false
        this.isDisabled = false
        this.dialog.closeAll()
      }
    }, (error: HttpErrorResponse) => {
      this.isLoading = false
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong please try again...' });
    })
  }

  // ? CHECK IF THE NEW PASSWORD IS DISABLED
  checkDisabled() {
    if (this.profileModel.newPassword == null || this.profileModel.newPassword == '')
      this.isDisabled = true
    else
      this.isDisabled = false
  }

  // ? CHECK IF THE NEW PASSWORD AND CONFIRM NEW PASSWORD ARE EQUAL
  checkConfirmPassword() {
    if (this.profileModel.newPassword != this.profileModel.conPassword) {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: "Password and confirm new password don't match" });
      return false;
    }
  }

  // ? CHECK IF THE EMAIL IS UNIQUE OR NOT
  checkEmailUnique(event: any): void {
    const req = {
      tableName: 'user',
      columnName: 'email',
      id: this.profileModel.id,
      value: event.target.value,
      userId: parseInt(localStorage.getItem('userId')),
    }
    this.userService.checkUnique({ data: this.commonService.encryptData(req) }).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
    }, (error: HttpErrorResponse) => {
      if (error.status === 606) {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'This staff email is already exist...' });
        event.target.value = '';
        this.profileModel.email = null
      }
    })
  }
}
