import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit, Renderer2, inject } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { NotificationService } from 'src/@vex/layout/toolbar/toolbar-notifications/toolbar-notifications-dropdown/notification.service';
import { CommonService } from './shared/services/common.service';
import { UnSubscribe } from './shared/classes/un-sub';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends UnSubscribe implements OnInit {
  // ? VARIABLES
  routeUrls: any[] = []
  showLoader: boolean = false;

  // ? INJECTS
  notificationService = inject(NotificationService)
  commonService = inject(CommonService)
  cdr = inject(ChangeDetectorRef)

  constructor(private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer) {
    super()
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }
    });

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: 'link',
        label: 'Dashboard',
        tag: 'dashboard',
        route: '/dashboard',
        icon: 'assets/img/sidenav-icons/home.svg',
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: 'link',
        label: 'Orders',
        tag: 'orders',
        route: '/orders',
        icon: 'assets/img/sidenav-icons/orders.svg',
      },
      {
        type: 'link',
        label: 'Subscriptions',
        tag: 'subscriptions',
        route: '/subscriptions',
        icon: 'assets/img/sidenav-icons/subscriptions.svg',
      },
      {
        type: 'dropdown',
        label: 'Menu',
        tag: 'menu',
        icon: 'assets/img/sidenav-icons/menu.svg',
        children: [
          {
            type: 'link',
            label: 'Daily Menu',
            tag: 'dailyMenu',
            route: '/menu/daily-menu',
            icon: 'assets/img/sidenav-icons/daily-menu.svg',
            iconHover: 'assets/img/sidenav-icons/daily-menu-hover.svg',
          },
          {
            type: 'link',
            label: 'All Dishes',
            tag: 'allDishes',
            route: '/menu/all-dishes',
            icon: 'assets/img/sidenav-icons/all-dishes.svg',
            iconHover: 'assets/img/sidenav-icons/all-dishes-hover.svg',
          },
          {
            type: 'link',
            label: 'Reviewed Dishes',
            tag: 'reviewedDishes',
            route: '/menu/reviewed-dishes',
            icon: 'assets/img/sidenav-icons/reviewed-dishes.svg',
            iconHover: 'assets/img/sidenav-icons/reviewed-dishes-hover.svg',
          }
        ]
      },
      {
        type: 'dropdown',
        label: 'Users',
        tag: 'users',
        icon: 'assets/img/sidenav-icons/users.svg',
        children: [
          {
            type: 'link',
            label: 'Customers',
            tag: 'customers',
            route: '/users/customers',
            icon: 'assets/img/sidenav-icons/customers.svg',
            iconHover: 'assets/img/sidenav-icons/customers-hover.svg',
          },
          {
            type: 'link',
            label: 'Staffs',
            tag: 'staffs',
            route: '/users/staffs',
            icon: 'assets/img/sidenav-icons/staffs.svg',
            iconHover: 'assets/img/sidenav-icons/staffs-hover.svg',
          },
          {
            type: 'link',
            label: 'Roles & Permissions',
            tag: 'roles',
            route: '/users/roles',
            icon: 'assets/img/sidenav-icons/roles.svg',
            iconHover: 'assets/img/sidenav-icons/roles-hover.svg',
          },
        ]
      },
      {
        type: 'dropdown',
        label: 'Blog',
        tag: 'blog',
        icon: 'assets/img/sidenav-icons/blog.svg',
        children: [
          {
            type: 'link',
            label: 'Articles',
            tag: 'articles',
            route: '/blog/articles',
            icon: 'assets/img/sidenav-icons/articles.svg',
            iconHover: 'assets/img/sidenav-icons/articles-hover.svg'
          },
          {
            type: 'link',
            label: 'Categories',
            route: '/blog/categories',
            tag: 'articleCategories',
            icon: 'assets/img/sidenav-icons/article-categories.svg',
            iconHover: 'assets/img/sidenav-icons/article-categories-hover.svg'
          },
          {
            type: 'link',
            label: 'Article Comments',
            route: '/blog/comments',
            tag: 'articleComments',
            icon: 'assets/img/sidenav-icons/comments.svg',
            iconHover: 'assets/img/sidenav-icons/comments-hover.svg'
          },
        ]
      },
      {
        type: 'dropdown',
        label: 'Marketing',
        tag: 'marketing',
        icon: 'assets/img/sidenav-icons/marketing.svg',
        children: [
          {
            type: 'link',
            label: 'Coupons',
            tag: 'coupons',
            route: '/marketing/coupons',
            icon: 'assets/img/sidenav-icons/coupon.svg',
            iconHover: 'assets/img/sidenav-icons/coupon-hover.svg',
          },
          {
            type: 'link',
            label: 'Loyalty points',
            tag: 'loyaltyPoints',
            route: '/marketing/loyalty-points',
            icon: 'assets/img/sidenav-icons/loyalty-points.svg',
            iconHover: 'assets/img/sidenav-icons/loyalty-points-hover.svg',
          }
        ]
      },
      {
        type: 'link',
        label: 'Push Notifications',
        tag: 'pushNotifications',
        route: '/push-notifications',
        icon: 'assets/img/sidenav-icons/push-notifications.svg',
      },
      {
        type: 'link',
        label: 'Feedback',
        tag: 'feedback',
        route: '/feedback',
        icon: 'assets/img/sidenav-icons/feedback.svg',
      },
      {
        type: 'link',
        label: 'CMS',
        route: '/cms',
        tag: 'cms',
        icon: 'assets/img/sidenav-icons/cms.svg',
      },
      {
        type: 'dropdown',
        label: 'Masterdata',
        tag: 'masterdata',
        icon: 'assets/img/sidenav-icons/masterdata.svg',
        children: [
          {
            type: 'link',
            label: 'Meals & Snacks',
            route: '/masterdata/meals-and-snacks',
            tag: 'mealsAndSnacks',
            icon: 'assets/img/sidenav-icons/meals-and-snacks.svg',
            iconHover: 'assets/img/sidenav-icons/meals-and-snacks-hover.svg',
          },
          {
            type: 'link',
            label: 'Diet Categories',
            tag: 'dietCategories',
            route: '/masterdata/diet-categories',
            icon: 'assets/img/sidenav-icons/diet-type-categories.svg',
            iconHover: 'assets/img/sidenav-icons/diet-type-categories-hover.svg',
          },
          {
            type: 'link',
            label: 'Diet Types',
            tag: 'dietTypes',
            route: '/masterdata/diet-types',
            icon: 'assets/img/sidenav-icons/diet-types.svg',
            iconHover: 'assets/img/sidenav-icons/diet-types-hover.svg',
          },
          {
            type: 'link',
            label: 'Ingredient Categories',
            tag: 'ingredientCategories',
            route: '/masterdata/ingredient-categories',
            icon: 'assets/img/sidenav-icons/ingredient-categories.svg',
            iconHover: 'assets/img/sidenav-icons/ingredient-categories-hover.svg',
          },
          {
            type: 'link',
            label: 'Ingredients',
            tag: 'ingredients',
            route: '/masterdata/ingredients',
            icon: 'assets/img/sidenav-icons/ingredients.svg',
            iconHover: 'assets/img/sidenav-icons/ingredients-hover.svg',
          },
          {
            type: 'link',
            label: 'Heating Instructions',
            tag: 'heatingInstructions',
            route: '/masterdata/heating-instructions',
            icon: 'assets/img/sidenav-icons/heating-instruction.svg',
            iconHover: 'assets/img/sidenav-icons/heating-instruction-hover.svg',
          },
          {
            type: 'link',
            label: 'Plan Durations',
            route: '/masterdata/plan-durations',
            tag: 'planDurations',
            icon: 'assets/img/sidenav-icons/plan-duration.svg',
            iconHover: 'assets/img/sidenav-icons/plan-duration-hover.svg',
          },
          {
            type: 'link',
            label: 'Delivery Charges',
            route: '/masterdata/delivery-charges',
            tag: 'deliveryCharges',
            icon: 'assets/img/sidenav-icons/delivery-charges.svg',
            iconHover: 'assets/img/sidenav-icons/delivery-charges-hover.svg',
          },
          {
            type: 'link',
            label: 'Subscriptions Start Dates',
            tag: 'subscriptionsStartDates',
            route: '/masterdata/subscriptions-start-dates',
            icon: 'assets/img/sidenav-icons/subscription-start-date.svg',
            iconHover: 'assets/img/sidenav-icons/subscription-start-date-hover.svg',
          },
          // {
          //   type: 'link',
          //   label: 'Subscription Freeze',
          //   tag: 'subscriptionFreeze',
          //   route: '/masterdata/subscription-freeze',
          //   icon: 'assets/img/sidenav-icons/subscription-freeze.svg',
          //   iconHover: 'assets/img/sidenav-icons/subscription-freeze-hover.svg',
          // },
          {
            type: 'link',
            label: 'Subscription Cancellation',
            tag: 'subscriptionCancellations',
            route: '/masterdata/subscription-cancellation',
            icon: 'assets/img/sidenav-icons/cancel-subscription.svg',
            iconHover: 'assets/img/sidenav-icons/cancel-subscription-hover.svg',
          },
          {
            type: 'link',
            label: 'Delivery Shifts',
            tag: 'deliveryShifts',
            route: '/masterdata/delivery-shifts',
            icon: 'assets/img/sidenav-icons/delivery-shift.svg',
            iconHover: 'assets/img/sidenav-icons/delivery-shift-hover.svg',
          },
          {
            type: 'link',
            label: 'Location',
            route: '/masterdata/location',
            tag: 'location',
            icon: 'assets/img/sidenav-icons/location.svg',
            iconHover: 'assets/img/sidenav-icons/location-hover.svg',
          },
        ]
      },
      {
        type: 'link',
        label: 'Packages',
        tag: 'packages',
        route: '/packages',
        icon: 'assets/img/sidenav-icons/package.svg',
      },
      {
        type: 'dropdown',
        tag: 'reports',
        label: 'Reports',
        icon: 'assets/img/sidenav-icons/reports.svg',
        children: [
          {
            type: 'link',
            label: 'Payments Report',
            tag: 'paymentsReport',
            route: '/reports/payments-report',
            icon: 'assets/img/sidenav-icons/payment-report.svg',
            iconHover: 'assets/img/sidenav-icons/payment-report-hover.svg',
          },
          {
            type: 'link',
            label: 'Sales Report',
            tag: 'salesReport',
            route: '/reports/sales-report',
            icon: 'assets/img/sidenav-icons/sales-report.svg',
            iconHover: 'assets/img/sidenav-icons/sales-report-hover.svg',
          },
          {
            type: 'link',
            label: 'Delivery Areas Report',
            tag: 'areasReport',
            route: '/reports/area-report',
            icon: 'assets/img/sidenav-icons/area-report.svg',
            iconHover: 'assets/img/sidenav-icons/area-report-hover.svg',
          },
          {
            type: 'link',
            label: 'Blacklist Report',
            tag: 'blacklistReport',
            route: '/reports/blacklist-report',
            icon: 'assets/img/sidenav-icons/blacklist-report.svg',
            iconHover: 'assets/img/sidenav-icons/blacklist-report-hover.svg',
          },
          {
            type: 'link',
            label: 'Diet Types Report',
            tag: 'dietTypesReport',
            route: '/reports/diet-report',
            icon: 'assets/img/sidenav-icons/diet-report.svg',
            iconHover: 'assets/img/sidenav-icons/diet-report-hover.svg',
          },
          {
            type: 'link',
            label: 'Discount Codes Report',
            tag: 'discountReport',
            route: '/reports/discount-report',
            icon: 'assets/img/sidenav-icons/discount-report.svg',
            iconHover: 'assets/img/sidenav-icons/discount-report-hover.svg',
          },
          {
            type: 'link',
            label: 'Loyalty Points Report',
            tag: 'loyaltyReport',
            route: '/reports/loyalty-points-report',
            icon: 'assets/img/sidenav-icons/loyalty-report.svg',
            iconHover: 'assets/img/sidenav-icons/loyalty-report-hover.svg',
          },
          {
            type: 'link',
            label: 'Meal Selection Report',
            tag: 'mealSelectionReport',
            route: '/reports/meal-selection-report',
            icon: 'assets/img/sidenav-icons/meal-report.svg',
            iconHover: 'assets/img/sidenav-icons/meal-report-hover.svg',
          },
        ]
      },
      {
        type: 'link',
        label: 'Settings',
        tag: 'settings',
        route: '/settings',
        icon: 'assets/img/sidenav-icons/settings.svg',
      },
    ];
  }

  ngOnInit(): void {
    setInterval(() => {
      if (localStorage.getItem('userId'))
        this.getNotifications()
    }, 3000)

    this.commonService.loader$.subscribe(data => {
      this.showLoader = data
      this.cdr.detectChanges()
    })
  }

  // ? GET NOTIFICATIONS
  getNotifications(): void {
    const req = {
      "userId": parseInt(localStorage.getItem('userId'))
    }
    this.notificationService.getNotifications({ data: this.commonService.encryptData(req) }).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const response = this.commonService.decryptData({ data: res })
      this.commonService.notifications.set(response)
      if (response.length > 0)
        this.layoutService.showNotificationIndicator.set(true)
      else
        this.layoutService.showNotificationIndicator.set(false)
    })
  }
}