<div class="flex flex-col justify-between gap-8 p-4">
    <!-- * DIALOG HEADER -->
    <div class="flex items-center justify-between" matDialogTitle>
        <!-- * TITLE -->
        <!-- * REMEMBER YOU HAVE TO CHANGE THE TITLE AS 'SELECT INGREDIENTS' OR 'SELECT ALLERGIES AND DISLIKES' ACCORDINGLY -->
        <p class="text-xl" *ngIf="dialogTitle != null">
            {{
            dialogTitle == 'ingredient' ? 'Select Ingredients' : dialogTitle == 'allergies' ? 'Allergies and Dislikes'
            : ''
            }}
        </p>

        <!-- * SAVE BUTTON -->
        <button (click)="addIngredients()" color="accent" class="font-bold whitespace-nowrap px-6 lg:px-10 2xl:px-6"
            mat-raised-button>Save</button>
    </div>

    <!-- * DIALOG CONTENT -->
    <div class="flex flex-col gap-6" mat-dialog-content>
        <!-- * FILTERS -->
        <div class="flex flex-col sm:flex-row items-center gap-4">
            <!-- * SEARCH -->
            <div
                class="border border-input-border-color hover:border-primary-color w-full flex items-center justify-between rounded pr-2">
                <input (input)="search($event)" type="search" name="dishes-search" id="dishes-search"
                    placeholder="Search" class="px-[.7rem] py-[.45rem] w-full rounded outline-none " />

                <custom-svg class="w-[18px] h-[18px] mr-1" src="assets/img/actions/search.svg" />
            </div>

            <!-- * CATEGORY FILTER -->
            <div class="flex items-center justify-between relative">
                <mat-select [(ngModel)]="categoryId" placeholder="Filter by Categories"
                    (valueChange)="getIngredientByCategory($event)" class="min-w-[12rem] w-full">
                    <mat-option *ngFor="let category of ingredientCategoriesList; trackBy: trackBy"
                        [value]="category.id">{{category.nameEn}}</mat-option>
                </mat-select>
                <mat-icon matTooltip="Clear" svgIcon="mat:close" *ngIf="categoryId != null"
                    class="cursor-pointer custom-svg absolute w-[17px] right-10" (click)="clearCategory()"></mat-icon>
            </div>
        </div>

        <!-- * INGREDIENTS CHECKBOX LIST -->
        <div *ngIf="ingredientsList.length > 0" class="flex flex-col gap-4 max-h-[20rem] overflow-scroll">
            <mat-checkbox [checked]="ingredient.checked" (change)="getIngredient($event, ingredient)"
                *ngFor="let ingredient of ingredientsList; trackBy: trackBy">
                <div class="flex ml-2 items-center gap-2">
                    <img width="48" height="48" class="w-12 h-12 object-cover rounded-md"
                        [src]="ingredient.imgUrl != null ? ingredient.imgUrl : 'assets/img/no-image.svg'"
                        [alt]="ingredient.nameEn">
                    <p>{{ingredient.nameEn}}</p>
                </div>
            </mat-checkbox>
        </div>
    </div>
</div>