import { Routes } from "@angular/router";

export const content: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../../pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'orders',
        loadChildren: () => import('../../pages/orders/orders.module').then(m => m.OrdersModule),
    },
    {
        path: 'subscriptions',
        loadChildren: () => import('../../pages/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
    },
    {
        path: 'menu',
        loadChildren: () => import('../../pages/menu/menu.module').then(m => m.MenuModule),
    },
    {
        path: 'users',
        loadChildren: () => import('../../pages/users/users.module').then(m => m.UsersModule),
    },
    {
        path: 'blog',
        loadChildren: () => import('../../pages/blog/blog.module').then(m => m.BlogModule),
    },
    {
        path: 'marketing',
        loadChildren: () => import('../../pages/marketing/marketing.module').then(m => m.MarketingModule),
    },
    {
        path: 'push-notifications',
        loadChildren: () => import('../../pages/push-notifications/push-notifications.module').then(m => m.PushNotificationsModule),
    },
    {
        path: 'feedback',
        loadChildren: () => import('../../pages/feedback/feedback.module').then(m => m.FeedbackModule),
    },
    {
        path: 'cms',
        loadChildren: () => import('../../pages/cms/cms.module').then(m => m.CmsModule),
    },
    {
        path: 'masterdata',
        loadChildren: () => import('../../pages/masterdata/masterdata.module').then(m => m.MasterdataModule),
    },
    {
        path: 'packages',
        loadChildren: () => import('../../pages/packages/packages.module').then(m => m.PackagesModule),
    },
    {
        path: 'reports',
        loadChildren: () => import('../../pages/reports/reports.module').then(m => m.ReportsModule),
    },
    {
        path: 'settings',
        loadChildren: () => import('../../pages/settings/settings.module').then(m => m.SettingsModule),
    },
    {
        path: '**',
        redirectTo: '/error',
        pathMatch: 'full'
    },
    {
        path: 'error',
        loadChildren: () => import('../../pages/error/error.module').then(m => m.ErrorModule),
    },
    {
        path: 'access-denied',
        loadChildren: () => import('../../pages/access-denied/access-denied.module').then(m => m.AccessDeniedModule),
    },
];
