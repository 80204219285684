import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  // ? INJECTS
  http = inject(HttpClient)

  // ? ROLES & PERMISSIONS
  getPrivileges(req: any): Observable<any> {
    return this.http.post('privileges', req);
  }

  addRole(req: any): Observable<any> {
    return this.http.post('role/add', req);
  }

  updateRole(req: any): Observable<any> {
    return this.http.post('role/update', req);
  }

  deleteRole(req: any): Observable<any> {
    return this.http.post('role/delete', req);
  }

  getRoleById(req: any): Observable<any> {
    return this.http.post('role', req);
  }

  getRoles(req: any): Observable<any> {
    return this.http.post('roles', req);
  }

  getRolesList(req: any): Observable<any> {
    return this.http.post('roles/list', req);
  }

  // ? CUSTOMERS
  addCustomer(req: any): Observable<any> {
    return this.http.post('customer/add', req);
  }

  updateCustomer(req: any): Observable<any> {
    return this.http.post('customer/update', req);
  }

  getCustomerById(req: any): Observable<any> {
    return this.http.post('customer', req);
  }

  getCustomers(req: any): Observable<any> {
    return this.http.post('customers', req);
  }

  getCustomersList(req: any): Observable<any> {
    return this.http.post('customers/list', req);
  }

  // ? UNIQUE CHECK
  checkUnique(req: any): Observable<any> {
    return this.http.post('unique/check', req);
  }

  // ? ADDRESSES
  addAddress(req: any): Observable<any> {
    return this.http.post('address/add', req);
  }

  updateAddress(req: any): Observable<any> {
    return this.http.post('address/update', req);
  }

  deleteAddress(req: any): Observable<any> {
    return this.http.post('address/delete', req);
  }

  getAddressById(req: any): Observable<any> {
    return this.http.post('address', req);
  }

  getAddresses(req: any): Observable<any> {
    return this.http.post('addresses', req);
  }

  getAddressesList(req: any): Observable<any> {
    return this.http.post('addresses/list', req);
  }

  // ? WEIGHT
  addWeight(req: any): Observable<any> {
    return this.http.post('customer/weight/add', req);
  }

  getWeight(req: any): Observable<any> {
    return this.http.post('customer/weight/graph', req);
  }

  // ? BODY FAT
  addBodyFat(req: any): Observable<any> {
    return this.http.post('customer/fat/add', req);
  }

  getBodyFat(req: any): Observable<any> {
    return this.http.post('customer/fat/graph', req);
  }

  // ? LIKED DISHES
  getLikedDishesList(req: any): Observable<any> {
    return this.http.post('customer/liked/dishes', req);
  }

  // ? LIKED ARTICLES
  getLikedArticlesList(req: any): Observable<any> {
    return this.http.post('customer/liked/articles', req);
  }

  // ? STAFFS
  addStaff(req: any): Observable<any> {
    return this.http.post('user/add', req);
  }

  updateStaff(req: any): Observable<any> {
    return this.http.post('user/update', req);
  }

  deleteStaff(req: any): Observable<any> {
    return this.http.post('user/delete', req);
  }

  getStaffById(req: any): Observable<any> {
    return this.http.post('user', req);
  }

  getStaffs(req: any): Observable<any> {
    return this.http.post('users', req);
  }

  getStaffsList(req: any): Observable<any> {
    return this.http.post('users/list', req);
  }
}
