import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { ToolbarNotificationsModule } from './toolbar-notifications/toolbar-notifications.module';
import { ToolbarUserModule } from './toolbar-user/toolbar-user.module';
import { NavigationModule } from '../navigation/navigation.module';
import { RouterModule } from '@angular/router';
import { NavigationItemModule } from '../../components/navigation-item/navigation-item.module';
import { PopoverModule } from 'src/@vex/components/popover/popover.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    CommonModule,
    ToolbarNotificationsModule,
    ToolbarUserModule,
    PopoverModule,
    NavigationModule,
    RouterModule,
    NavigationItemModule,
    SharedModule
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {
}
