<div #originRef (click)="showPopover()" class="mr-2"
  [class.notification-indicator]="layoutService.showNotificationIndicator()">
  <span class="custom-svg">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256.000000 256.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M1182 2534 c-89 -44 -118 -97 -128 -230 -5 -56 -11 -84 -19 -84 -7 0
-49 -18 -94 -41 -215 -108 -363 -295 -417 -528 -11 -44 -18 -156 -24 -336 -7
-242 -10 -280 -33 -368 -44 -166 -141 -349 -256 -480 -59 -67 -67 -111 -29
-147 21 -19 33 -20 375 -20 328 0 354 -1 359 -18 68 -222 290 -334 504 -253
100 38 185 134 224 254 5 16 31 17 359 17 342 0 354 1 375 20 37 35 30 81 -22
138 -119 135 -218 319 -262 489 -24 88 -27 125 -34 368 -9 279 -18 347 -60
456 -63 161 -213 325 -371 404 -50 25 -97 45 -104 45 -8 0 -14 27 -19 84 -3
47 -11 97 -17 113 -19 51 -61 95 -113 119 -68 32 -128 31 -194 -2z m146 -144
c17 -17 22 -32 22 -75 l0 -55 -70 0 -70 0 0 55 c0 62 24 95 70 95 14 0 36 -9
48 -20z m142 -310 c100 -33 168 -77 251 -159 60 -60 85 -94 117 -161 22 -47
45 -107 50 -135 6 -27 13 -156 16 -285 7 -264 20 -364 71 -517 38 -115 78
-197 141 -296 24 -37 44 -70 44 -72 0 -3 -396 -5 -880 -5 -484 0 -880 2 -880
5 0 2 21 36 46 76 90 139 158 315 189 485 9 55 18 182 21 329 7 269 15 321 72
430 52 100 172 219 272 266 66 32 94 41 185 63 48 12 222 -3 285 -24z m20
-1788 c0 -4 -11 -24 -25 -44 -63 -93 -200 -122 -297 -65 -42 24 -98 87 -98
109 0 5 95 8 210 8 116 0 210 -3 210 -8z" />
        <path d="M475 2269 c-60 -53 -153 -186 -205 -293 -67 -137 -100 -262 -107
-407 -6 -112 -5 -116 17 -137 27 -25 66 -28 93 -8 23 18 37 68 37 133 0 172
93 396 235 567 71 86 81 120 45 156 -30 30 -73 25 -115 -11z" />
        <path d="M1970 2280 c-36 -36 -26 -70 45 -155 135 -162 215 -345 230 -524 11
-129 16 -151 38 -171 27 -26 67 -25 97 2 22 21 23 25 17 137 -11 207 -88 419
-217 589 -103 137 -161 171 -210 122z" />
      </g>
    </svg>
  </span>
</div>