import { Component, OnInit, inject } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../../utils/track-by';
import { NotificationService } from './notification.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { takeUntil } from 'rxjs';
import { UnSubscribe } from 'src/app/shared/classes/un-sub';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdateFeedbackComponent } from 'src/app/pages/feedback/components/update-feedback/update-feedback.component';
import { LayoutService } from 'src/@vex/services/layout.service';
import { CancelRequestComponent } from 'src/app/pages/subscriptions/dialogs/cancel-request/cancel-request.component';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent extends UnSubscribe implements OnInit {
  // ? VARIABLES
  trackById = trackById;
  notificationsList: any = []
  notificationCount: number = 0

  // ? INJECTS
  notificationService = inject(NotificationService)
  commonService = inject(CommonService)
  layoutService = inject(LayoutService)
  dialog = inject(MatDialog)
  router = inject(Router)

  ngOnInit() {
    this.notificationsList = this.commonService.notifications()
  }

  // ? GET NOTIFICATIONS
  getNotifications(): void {
    const req = {
      "userId": parseInt(localStorage.getItem('userId'))
    }
    this.notificationService.getNotifications({ data: this.commonService.encryptData(req) }).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const response = this.commonService.decryptData({ data: res })
      this.notificationsList = response
      this.notificationCount = response.length
      if (response.length > 0)
        this.layoutService.showNotificationIndicator.set(true)
    })
  }

  // ? MARK ALL NOTIFICATIONS AS READ
  markAllAsRead(): void {
    const req = {
      "userId": parseInt(localStorage.getItem('userId'))
    }
    this.notificationService.markAllAsRead({ data: this.commonService.encryptData(req) }).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const response = this.commonService.decryptData({ data: res })
      if (response.status === 'success') {
        this.commonService.notifications.set([])
        this.getNotifications()
        this.layoutService.showNotificationIndicator.set(false)
      }
    })
  }

  // ? DECIDE WETHER YOU WANT TO GO TO SUBSCRIPTION EDIT OR FEEDBACK EDIT
  goToEdit(id: number, option: number, customerId: number): void {
    this.layoutService.showNotificationIndicator.set(false)
    if (option === 0) {
      const data = {
        id: id,
        option: 1
      }
      this.router.navigate(['/subscriptions/subscription'], {
        state: { data },
      });
    }
    else if (option === 1) {
      const dialog = this.dialog.open(UpdateFeedbackComponent, {
        width: '1000px',
        position: {
          top: '100px'
        },
        data: { id }
      })

      dialog.afterClosed().subscribe((res) => {
        if (res) {
          this.router.navigate(['/feedback'], {
            state: { delete: 0 },
          });
        }
      });
    }
    else {
      this.router.navigateByUrl('/subscriptions')
    }
  }
}
