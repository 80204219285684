import { AfterViewInit, Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { MasterdataService } from 'src/app/pages/masterdata/services/masterdata.service';
import { UnSubscribe } from '../../classes/un-sub';
import { CommonService } from '../../services/common.service';
import { trackById } from 'src/@vex/utils/track-by';
import { environment } from 'src/environments/environment';
import { GetList } from '../../models/common';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-ingredients-selection',
  templateUrl: './ingredients-selection.component.html',
  styleUrls: ['./ingredients-selection.component.scss']
})
export class IngredientsSelectionComponent extends UnSubscribe implements OnInit, AfterViewInit {
  // ? INJECTS
  masterService = inject(MasterdataService)
  commonService = inject(CommonService)
  messageService = inject(MessageService)

  // ? MODELS
  listModel: GetList = new GetList();

  // ? VARIABLES
  ingredientsList: any[] = []
  ingredientCategoriesList: any[] = []
  selectedIngredients: any[] = []
  categoryId: number = null
  ingredientsCount: number = null
  dialogTitle: string = null
  trackBy = trackById;
  url = environment.RESTBASEURL;

  constructor(public dialogRef: MatDialogRef<IngredientsSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = null) {
    super()
    this.getIngredients('normal')
  }

  ngOnInit(): void {
    this.getIngredientCategoriesList()
  }

  ngAfterViewInit(): void {
  }

  onScroll(): void {
    if (this.ingredientsList.length < this.ingredientsCount) {
      this.listModel.offset = this.ingredientsList.length
      this.getIngredients('scroll')
    }
  }

  // ? GET INGREDIENTS DATA
  getIngredients(option: string): void {
    var req = {
      search: this.listModel.search,
      categoryId: this.categoryId,
      userId: this.listModel.userId,
    };
    this.masterService.getIngredientsList({ data: this.commonService.encryptData(req) }).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      const response = this.commonService.decryptData({ data: res });
      // if (option === 'scroll') {
      //   if (this.ingredientsList.length < response.count)
      //     this.ingredientsList = [...this.ingredientsList, ...response.result]
      // }
      // else
      this.ingredientsList = response
      this.listModel.pageCount = response.count;
      this.ingredientsCount = response.count;
      let i = this.listModel.offset + 1;
      this.ingredientsList.forEach((ingredient) => {
        if (ingredient.imgUrl != null) {
          const timestamp = Date.now();
          if (ingredient.imgUrl.includes(this.url)) {
            ingredient.imgUrl = `${ingredient.imgUrl}?timestamp=${timestamp}`;
          }
          else
            ingredient.imgUrl = `${this.url}${ingredient.imgUrl}?timestamp=${timestamp}`;
        }
        ingredient.sno = i++;
      });

      if (this.data != null) {
        this.dialogTitle = this.data.option
        this.data.list.forEach((data: any) => {
          const filteredIngredients = this.ingredientsList.filter(ingredient => ingredient.id === data.id)
          if (filteredIngredients.length > 0) {
            filteredIngredients[0].checked = true
            this.selectedIngredients.push(filteredIngredients[0])
          }
        })
        const uniqueSet = new Set(); // ? Create a Set to store unique IDs
        this.selectedIngredients = this.selectedIngredients.filter(ingredient => {
          const isUnique = !uniqueSet.has(ingredient.id); // ? Check if ID is unique
          uniqueSet.add(ingredient.id); // ? Add ID to the Set for future checks
          return isUnique;
        });
      }
    });
  }

  // ? GET INGREDIENT CATEGORIES LIST
  getIngredientCategoriesList(): void {
    const req = {
      userId: parseInt(localStorage.getItem('userId'))
    }
    this.masterService.getIngredientCategoriesList({ data: this.commonService.encryptData(req) }).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.ingredientCategoriesList = this.commonService.decryptData({ data: res })
    })
  }

  // ? SEARCH THE INGREDIENTS
  search(event: any): void {
    this.listModel.search =
      event.target.value === "" ? null : event.target.value;
    this.listModel.offset = 0
    this.getIngredients('normal');
  }

  // ? GET INGREDIENTS BY ITS CATEGORY
  getIngredientByCategory(event: number): void {
    this.categoryId = event
    this.getIngredients('normal')
  }

  // ? CLEAR CATEGORY
  clearCategory(): void {
    this.categoryId = null
    this.getIngredients('normal')
  }

  // ? GET THE INGREDIENT FROM THE CHECKBOX
  getIngredient(event: any, ingredient: any): void {
    if (event.checked) {
      this.selectedIngredients.push(ingredient)
      ingredient.checked = true
    }
    else {
      ingredient.checked = false
      const index = this.selectedIngredients.findIndex((selectedIngredient: any) => selectedIngredient.id === ingredient.id);
      if (index !== -1)
        this.selectedIngredients.splice(index, 1)
    }
  }

  addIngredients(): void {
    const uniqueSet = new Set(); // ? Create a Set to store unique IDs
    const selectedIngredients = this.selectedIngredients.filter(ingredient => {
      const isUnique = !uniqueSet.has(ingredient.id); // ? Check if ID is unique
      uniqueSet.add(ingredient.id); // ? Add ID to the Set for future checks
      return isUnique;
    });
    if (selectedIngredients.length === 0) {
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Select at least one ingredient...' });
      return
    }
    this.dialogRef.close(selectedIngredients)
  }
}
