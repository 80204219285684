<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button matTooltip="Toggle Sidebar" (click)="mobileQuery ? openSidenav() : toggleCollapse()" mat-icon-button
    type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <div class="px-1" matTooltip="Notifications">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>

    <div *ngIf="userVisible$ | async" class="px-2" matTooltip="Profile">
      <vex-toolbar-user></vex-toolbar-user>
    </div>
  </div>
</div>

<!-- <vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
  [class.hidden]="mobileQuery"></vex-navigation> -->