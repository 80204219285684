<div (mouseenter)="collapseOpenSidenav()" (mouseleave)="collapseCloseSidenav()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="sidenav flex flex-col">
  <div class="vex-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex items-center justify-center flex-col mb-6 mt-10">
      <a routerLink="/">
        <img src="assets/img/rouh-logo.svg" alt="Rouh" class="w-[4.2rem]">
      </a>
      <h1 *ngIf="showProjectTitle" class="text-[2rem]">Rouh</h1>
      <p *ngIf="showProjectTitle && userName != null" class="text-[15px]">{{userName}}</p>
      <p *ngIf="showProjectTitle && userEmail != null" class="text-[15px] mt-1">{{userEmail}}</p>
    </div>

    <!-- * DIVIDER -->
    <div class="vex-sidenav-toolbar__divider"></div>
  </div>

  <!-- * NAV MENUS -->
  <vex-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <vex-sidenav-item *ngFor="let item of navItems; trackBy: trackByRoute" [item]="item"
        [level]="0"></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>