export class Feedback {
    adminComment: string = null;
    email: string = null;
    name: string = null;
    phone: string = null;
    userComment: string = null;
    status: number = null;
    id: number = null;
    tz: any = null
    createdAt: any = null
    userId: number = parseInt(localStorage.getItem('userId'));
}