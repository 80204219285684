import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  // ? INJECTS
  http = inject(HttpClient)

  updateFeedback(req: any): Observable<any> {
    return this.http.post('feedback/update', req);
  }

  getFeedbackById(req: any): Observable<any> {
    return this.http.post('feedback', req);
  }

  getFeedbacks(req: any): Observable<any> {
    return this.http.post('feedbacks', req);
  }

  getFeedbacksList(req: any): Observable<any> {
    return this.http.post('feedbacks/list', req);
  }
}
