import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
  // ? INJECTS
  router = inject(Router)
  messageService = inject(MessageService)

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // ? AUTH TOKEN
    const authToken = localStorage.getItem('token');

    if (request.method === 'POST') { // ? HERE I PUT POST METHOD ONLY BECAUSE, I USE GET METHOD FOR CUSTOM SVG COMPONENT 
      if (authToken) {
        request = request.clone({
          url: environment.RESTBASEURL + request.url,
          setHeaders: {
            'Authorization': `${authToken}`,
          },
        });
      } else {
        request = request.clone({
          url: environment.RESTBASEURL + request.url
        });
      }
    } else {
      // ? THIS IS FOR LOCAL ASSETS IMAGES
      request = request.clone({
        url: request.url
      });
    }

    // ? IF USER SIGN IN IN ANOTHER DEVICE IT WILL LOG OUT FROM CURRENT THE DEVICE
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 603) {
          localStorage.clear()
          this.router.navigateByUrl('/login')
        }
        return throwError(error);
      })
    );
  }
}