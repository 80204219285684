import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import { LayoutService } from 'src/@vex/services/layout.service';
import { NotificationService } from './toolbar-notifications-dropdown/notification.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { takeUntil } from 'rxjs';
import { UnSubscribe } from 'src/app/shared/classes/un-sub';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent extends UnSubscribe implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;

  // ? INJECTS
  layoutService = inject(LayoutService)
  notificationService = inject(NotificationService)
  commonService = inject(CommonService)

  constructor(private popover: PopoverService,
    private cd: ChangeDetectorRef) {
    super()
  }

  ngOnInit() {
    this.getNotifications()
  }

  // ? GET NOTIFICATIONS
  getNotifications(): void {
    const req = {
      "userId": parseInt(localStorage.getItem('userId'))
    }
    this.notificationService.getNotifications({ data: this.commonService.encryptData(req) }).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const response = this.commonService.decryptData({ data: res })
      if (response.length > 0)
        this.layoutService.showNotificationIndicator.set(true)
      else
        this.layoutService.showNotificationIndicator.set(false)
    })
  }

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
