import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from './modules/material.module';
import { CustomSvgComponent } from './components/custom-svg/custom-svg.component';
import { FormsModule } from '@angular/forms';
import { ShakeDirective } from './directives/dnd.directive';
import { IngredientsSelectionComponent } from './components/ingredients-selection/ingredients-selection.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// ? PRIMENG IMPORTS
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { RestrictCharactersDirective } from './directives/restrict-characters.directive';

@NgModule({
  declarations: [
    CustomSvgComponent,
    ShakeDirective,
    RestrictCharactersDirective,
    IngredientsSelectionComponent,
    RestrictCharactersDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    InfiniteScrollModule,
    FormsModule,
    ToastModule,
    AccordionModule
  ],
  exports: [
    MaterialModule,
    InfiniteScrollModule,
    CustomSvgComponent,
    FormsModule,
    ShakeDirective,
    ToastModule,
    RestrictCharactersDirective,
    AccordionModule
  ],
  providers: [
    MessageService,
    DatePipe
  ]
})
export class SharedModule { }
