import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const AuthGuard: CanActivateFn = (route, state) => {
  // ? INJECTS
  const router = inject(Router)
  const authService = inject(AuthService)
  const isLoggedIn = localStorage.getItem('userId');

  // ? IF THE USER IS NOT LOGGED IN AND TRYING TO ACCESS ANY PAGE EXCEPT THE LOGIN, REDIRECT TO THE LOGIN PAGE
  if (authService.isAuthenticated)
    return

  if (!isLoggedIn)
    return authService.logOut()
};
