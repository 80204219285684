<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center ml-1">
      <div class="dropdown-heading-icon flex items-center justify-center">
        <mat-icon svgIcon="mat:person"></mat-icon>
      </div>
      <div class="dropdown-heading">{{userName ?? ''}}</div>
    </div>
  </div>

  <div class="dropdown-content">
    <a (click)="close(); openChangePasswordDialog(editProfile)"
      class="notification cursor-pointer group flex items-center" matRipple>
      <span class="fill-[#000000DE] group-hover:fill-accent-color mr-2 mb-[2px]">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M2420 4964 c-553 -70 -982 -418 -1149 -931 -55 -167 -63 -245 -68
      -648 l-6 -371 -67 -11 c-163 -25 -287 -127 -351 -288 l-24 -60 -3 -1030 c-2
      -738 0 -1049 9 -1098 30 -184 166 -327 347 -366 38 -8 448 -11 1455 -11 1515
      0 1454 -2 1565 51 113 55 208 189 231 326 9 49 11 360 9 1098 l-3 1030 -24 60
      c-64 161 -188 263 -351 288 l-67 11 -6 371 c-5 403 -13 481 -68 648 -111 341
      -351 624 -665 784 -114 59 -246 105 -365 128 -82 16 -329 28 -399 19z m358
      -318 c338 -72 620 -305 755 -626 68 -160 70 -177 74 -612 l4 -398 -1051 0
      -1051 0 4 398 c3 369 5 402 25 473 58 206 151 360 302 503 169 159 353 243
      615 280 56 8 250 -3 323 -18z m1212 -1955 c19 -10 44 -34 55 -52 20 -33 20
      -52 20 -1059 0 -1004 0 -1026 -20 -1058 -11 -18 -38 -41 -60 -52 -41 -20 -67
      -20 -1425 -20 -1358 0 -1384 0 -1425 20 -22 11 -49 34 -60 52 -20 32 -20 54
      -20 1058 0 1013 0 1025 20 1060 12 19 36 43 55 52 33 17 122 18 1430 18 1342
      0 1397 -1 1430 -19z" />
            <path d="M2440 2242 c-36 -9 -85 -29 -110 -44 -198 -116 -276 -373 -178 -581
      43 -90 115 -165 197 -204 l60 -28 1 -185 c0 -156 3 -191 18 -220 49 -99 215
      -99 264 0 15 29 18 64 18 220 l1 185 60 28 c131 62 226 205 236 354 16 233
      -121 426 -338 478 -82 19 -148 18 -229 -3z m212 -313 c83 -59 72 -211 -18
      -254 -41 -19 -107 -19 -148 0 -84 40 -101 184 -28 248 46 42 140 44 194 6z" />
          </g>
        </svg>
      </span>
      <p class="notification-label">Update Profile Password</p>
    </a>
    <a (click)="close(); logout()" class="notification group flex items-center ml-1 cursor-pointer" matRipple>
      <span class="fill-[#000000DE] group-hover:fill-accent-color mr-2">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M325 5106 c-148 -37 -271 -159 -311 -310 -11 -42 -14 -384 -14 -2021
0 -2157 -3 -2031 57 -2135 37 -62 111 -134 169 -163 27 -13 350 -125 719 -248
l670 -224 90 0 c110 0 172 20 255 81 67 49 121 123 150 207 16 46 20 82 20
202 l0 145 378 0 c285 0 393 4 443 14 207 44 391 223 444 431 22 87 22 1131 0
1175 -32 62 -115 99 -183 81 -46 -13 -99 -65 -112 -112 -6 -20 -10 -242 -10
-549 l0 -515 -24 -51 c-13 -27 -42 -66 -66 -87 -76 -65 -90 -67 -502 -67
l-368 0 -2 1708 -3 1707 -31 66 c-70 147 -138 194 -420 288 l-209 70 701 0
c779 1 753 3 834 -66 24 -21 53 -60 66 -87 l24 -51 0 -515 c0 -317 4 -529 10
-552 14 -49 76 -104 129 -113 53 -9 126 25 158 74 l23 34 0 546 c0 602 0 606
-63 731 -21 42 -59 92 -108 142 -61 63 -92 85 -160 117 -46 22 -111 44 -143
50 -90 17 -2544 14 -2611 -3z m782 -527 c555 -185 666 -225 683 -246 20 -26
20 -38 20 -1985 l0 -1960 -34 -34 c-23 -23 -43 -34 -63 -34 -27 0 -1294 417
-1340 441 -11 6 -29 25 -38 43 -15 28 -16 191 -13 1982 3 1936 3 1952 23 1974
22 25 50 38 80 39 11 1 318 -98 682 -220z" />
            <path d="M4095 3826 c-41 -18 -83 -69 -91 -111 -16 -86 -14 -89 289 -392 l282
-283 -840 0 -840 0 -39 -23 c-109 -61 -106 -221 5 -277 37 -19 62 -20 876 -20
l838 0 -282 -282 c-304 -305 -305 -307 -288 -395 9 -49 69 -109 118 -118 91
-17 77 -29 540 433 236 235 435 439 443 454 18 35 18 101 0 136 -23 42 -857
870 -889 882 -38 14 -85 12 -122 -4z" />
          </g>
        </svg>
      </span>
      <p class="notification-label">Logout</p>
    </a>
  </div>
</div>

<!-- * EDIT PROFILE DIALOG -->
<ng-template #editProfile>
  <div class="flex flex-col justify-between gap-4 p-4">
    <!-- * DIALOG HEADER -->
    <div class="flex items-start justify-between" mat-dialog-title>
      <!-- * TITLE -->
      <h2 class="font-bold text-lg">Update Profile Password</h2>

      <!-- * CLOSE BUTTON -->
      <mat-icon matTooltip="Close" matDialogClose="true" svgIcon="mat:close"
        class="cursor-pointer hover:text-accent-color"></mat-icon>
    </div>

    <!-- * DIALOG CONTENT -->
    <form #form="ngForm" class="flex flex-col gap-4 w-full" (ngSubmit)="form.form.valid && updateProfile()">
      <div class="flex flex-col gap-4 w-full">
        <div class="flex flex-col gap-2 w-full">
          <label for="old-password">Old Password</label>
          <div
            class="border border-input-border-color hover:border-primary-color w-full flex items-center justify-between rounded pr-3">
            <input pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%_*?&])[.A-Za-z\d@$!_%*?&]{8,}$" maxlength="15"
              [ngClass]="{ 'error': form.submitted && oldPass.errors }" #oldPass="ngModel" #oldPassword required
              [(ngModel)]="profileModel.oldPassword" type="password" name="old-password" id="old-password"
              placeholder="Enter the Old Password" class="px-[.7rem] py-[.45rem] w-full rounded outline-none" />

            <button *ngIf="profileModel?.oldPassword?.length > 0" type="button"
              (click)="oldPassword.type === 'password' ? (oldPassword.type = 'text') : (oldPassword.type = 'password')">
              <custom-svg matTooltip="Show" *ngIf="oldPassword.type === 'password'" class="custom-svg"
                src="assets/img/actions/password-hide.svg" />
              <custom-svg matTooltip="Hide" *ngIf="oldPassword.type === 'text'" class="custom-svg"
                src="assets/img/actions/view.svg" />
            </button>
          </div>

          <!-- * VALIDATION -->
          <ng-container *ngIf="(form.submitted && oldPass.invalid) || oldPass?.touched">
            <div class="text-red-500" *ngIf="oldPass.errors?.['required']">
              This field is required
            </div>
            <div class="text-red-600" *ngIf="oldPass.errors?.['pattern']">
              Password should contain at least 8 characters, one uppercase, one special character, one number.
            </div>
          </ng-container>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <label for="new-password">New Password</label>
          <div
            class="border border-input-border-color hover:border-primary-color w-full flex items-center justify-between rounded pr-3">
            <input pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%_*?&])[.A-Za-z\d@$!_%*?&]{8,}$" maxlength="15"
              [ngClass]="{ 'error': form.submitted && oldPass.errors }" #newPass="ngModel" required
              (change)="checkDisabled()" #newPassword [(ngModel)]="profileModel.newPassword" type="password"
              name="new-password" id="new-password" placeholder="Enter the New Password"
              class="px-[.7rem] py-[.45rem] w-full rounded outline-none" />

            <button *ngIf="profileModel?.newPassword?.length > 0" type="button"
              (click)="newPassword.type === 'password' ? (newPassword.type = 'text') : (newPassword.type = 'password')">
              <custom-svg matTooltip="Show" *ngIf="newPassword.type === 'password'" class="custom-svg"
                src="assets/img/actions/password-hide.svg" />
              <custom-svg matTooltip="Hide" *ngIf="newPassword.type === 'text'" class="custom-svg"
                src="assets/img/actions/view.svg" />
            </button>
          </div>

          <!-- * VALIDATION -->
          <ng-container *ngIf="(form.submitted && newPass.invalid) || newPass?.touched">
            <div class="text-red-500" *ngIf="newPass.errors?.['required']">
              This field is required
            </div>
            <div class="text-red-600" *ngIf="newPass.errors?.['pattern']">
              Password should contain at least 8 characters, one uppercase, one special character, one number.
            </div>
          </ng-container>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <label for="confirm-new-password">Confirm New Password</label>
          <div
            class="border border-input-border-color hover:border-primary-color w-full flex items-center justify-between rounded pr-3">
            <input pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%_*?&])[.A-Za-z\d@$!_%*?&]{8,}$" maxlength="15"
              [ngClass]="{ 'error': form.submitted && oldPass.errors }" #conNewPass="ngModel" required
              (change)="checkConfirmPassword()" [disabled]="isDisabled" [(ngModel)]="profileModel.conPassword"
              #confirmNewPassword type="password" name="confirm-new-password" id="confirm-new-password"
              placeholder="Enter the Confirm New Password" class="px-[.7rem] py-[.45rem] w-full rounded outline-none" />

            <button *ngIf="profileModel?.conPassword?.length > 0" type="button"
              (click)="confirmNewPassword.type === 'password' ? (confirmNewPassword.type = 'text') : (confirmNewPassword.type = 'password')">
              <custom-svg matTooltip="Show" *ngIf="confirmNewPassword.type === 'password'" class="custom-svg"
                src="assets/img/actions/password-hide.svg" />
              <custom-svg matTooltip="Hide" *ngIf="confirmNewPassword.type === 'text'" class="custom-svg"
                src="assets/img/actions/view.svg" />
            </button>
          </div>

          <!-- * VALIDATION -->
          <ng-container *ngIf="(form.submitted && conNewPass.invalid) || conNewPass?.touched">
            <div class="text-red-500" *ngIf="conNewPass.errors?.['required']">
              This field is required
            </div>
            <div class="text-red-600" *ngIf="conNewPass.errors?.['pattern']">
              Password should contain at least 8 characters, one uppercase, one special character, one number.
            </div>
          </ng-container>
        </div>
      </div>

      <button color="accent" class="font-bold px-6 lg:px-10 2xl:px-6 self-end" mat-raised-button>Update</button>
    </form>
  </div>
</ng-template>