import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn } from '@angular/router';
import { Router } from '@angular/router';

export const NoAuthGuard: CanActivateFn = (route, state) => {
  // ? INJECTS
  const router = inject(Router)
  const isLoggedIn = localStorage.getItem('userId');

  // ? IF THE USER IS LOGGED IN AND TRYING TO ACCESS THE LOGIN PAGE, REDIRECT TO THE HOME PAGE
  if (isLoggedIn) {
    router.navigateByUrl('/')
    return false
  } else {
    return true
  }
}
