<ng-template #sidenavRef>
  <vex-sidenav [collapsed]="sidenavCollapsed$ | async"></vex-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <vex-toolbar [collapsed]="sidenavCollapsed$ | async" [hasShadow]="toolbarShadowEnabled$ | async"
    [mobileQuery]="!(isDesktop$ | async)" class="vex-toolbar"></vex-toolbar>
</ng-template>

<ng-template #footerRef>
  <vex-footer *ngIf="isFooterVisible$ | async" class="vex-footer"></vex-footer>
</ng-template>

<vex-layout [footerRef]="footerRef" [sidenavRef]="sidenavRef" [toolbarRef]="toolbarRef"></vex-layout>
<p-toast></p-toast>