import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[restrictCharacters]'
})
export class RestrictCharactersDirective {
  @Input() allowedCharacters: string = '';

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    // ... (existing code)
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData.getData('text/plain');
    const sanitizedText = pastedText.replace(/[^a-zA-Z0-9]/g, ''); // Adjust regex
    event.clipboardData.setData('text/plain', sanitizedText);
  }
}
