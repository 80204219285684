<div class="flex flex-col justify-between p-4">
    <!-- * DIALOG HEADER -->
    <div class="flex items-start justify-between">
        <h2 class="font-bold text-lg">Feedback Details</h2>

        <!-- * FEEDBACK DATE & CUSTOMER NAME -->
        <div class="flex flex-col items-center mr-24">
            <p class="font-bold">{{feedbackModel.createdAt == null ? '-' : (feedbackModel.createdAt |
                date: 'yyyy-MM-dd')}}</p>
            <p class="font-bold">{{feedbackModel.name != null ? feedbackModel.name : '-'}}</p>
            <p class="font-bold">{{feedbackModel.phone != null ? feedbackModel.phone : '-'}}</p>
        </div>

        <!-- * CLOSE BUTTON -->
        <mat-icon matTooltip="Close" matDialogClose="true" svgIcon="mat:close"
            class="cursor-pointer hover:text-accent-color"></mat-icon>
    </div>

    <!-- * DIALOG CONTENT -->
    <form #form="ngForm" (ngSubmit)="form.form.valid && updateFeedback()" class="flex flex-col gap-4">
        <!-- * FEEDBACK CONTENT -->
        <div class="flex flex-col gap-2">
            <label for="feeback-content">Feedback Content</label>
            <textarea [(ngModel)]="feedbackModel.userComment"
                class="border border-input-border-color hover:border-primary-color px-[.7rem] py-1.5 rounded outline-none"
                name="feeback-content" id="feeback-content" rows="4" readonly></textarea>
        </div>

        <!-- * ADMIN COMMENT -->
        <div class="flex flex-col gap-2">
            <label for="admin-comment">Admin Comment <span class="text-red-500 font-bold">*</span></label>
            <input maxlength="300" required pattern="^([\u0600-\u06FF\-.&,'\s]*|[a-zA-Z0-9\-.&()', ]*)$"
                [ngClass]="{ 'error': form.submitted && admin.errors }" #admin="ngModel"
                [(ngModel)]="feedbackModel.adminComment" type="text" name="admin-comment" id="admin-comment"
                placeholder="Enter the Comment" class="input" />

            <!-- * VALIDATION -->
            <ng-container *ngIf="(form.submitted && admin.invalid) || admin?.touched">
                <div class="text-red-500" *ngIf="admin.errors?.['required']">
                    This field is required
                </div>
                <div class="text-red-600" *ngIf="admin.errors?.['pattern']">
                    You can enter common characters only. Also, you cannot mix both languages
                </div>
            </ng-container>
        </div>

        <div class="flex items-center gap-2 self-end">
            <!-- * DISCARD NOTIFICATION BUTTON -->
            <button mat-dialog-close="true" color="accent" class="font-bold px-6 lg:px-10 2xl:px-6"
                mat-button>Discard</button>

            <!-- * MARK AS ATTENDED BUTTON -->
            <button color="accent" class="font-bold px-6 lg:px-10 2xl:px-6" mat-raised-button>Mark as
                attended</button>
        </div>
    </form>
</div>

<p-toast></p-toast>