import { Injectable, inject, signal } from '@angular/core';
import { AES } from 'crypto-js';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  // ? INJECTS
  http = inject(HttpClient)
  navService = inject(NavigationService)
  privileges: string[] = []

  // ? Signals
  showSyncLoader = signal(false)
  notifications = signal([])

  private _loaderSubject = new BehaviorSubject(false);
  loader$ = this._loaderSubject.asObservable();

  // ? ENCRYPTION
  encryptData(value: any) {
    return AES.encrypt(
      JSON.stringify(value),
      environment.SECRETKEY
    ).toString();
  }

  // ? DECRYPTION
  decryptData(value: any) {
    return JSON.parse(
      AES.decrypt(value.data, environment.SECRETKEY).toString(
        CryptoJS.enc.Utf8
      )
    );
  }

  // ? SINGLE IMAGE UPLOAD
  singleImageUpload(req: any) {
    return this.http.post('image/single/upload', req);
  }

  // ? MULTIPLE IMAGE UPLOAD
  multipleImageUpload(req: any) {
    return this.http.post('image/multi/upload', req);
  }

  // ? GET THE COUNT
  getCount(req: any) {
    return this.http.post('status/count', req);
  }

  checkUrl(option: string) {
    this.privileges = (localStorage.getItem('privileges')).split(',')
    for (let prv of this.privileges)
      if (option == prv)
        return true
    return false;
  }

  updateLoaderSubject(value: boolean) {
    this._loaderSubject.next(value);
  }
}
