import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterdataService {
  // ? INJECTS
  http = inject(HttpClient)

  // ? MEALS & SNACKS
  addMealAndSnack(req: any): Observable<any> {
    return this.http.post('meal/snack/add', req);
  }

  updateMealAndSnack(req: any): Observable<any> {
    return this.http.post('meal/snack/update', req);
  }

  deleteMealAndSnack(req: any): Observable<any> {
    return this.http.post('meal/snack/delete', req);
  }

  getMealAndSnackById(req: any): Observable<any> {
    return this.http.post('meal/snack', req);
  }

  getMealsAndSnacks(req: any): Observable<any> {
    return this.http.post('meals/snacks', req);
  }

  getMealsAndSnacksList(req: any): Observable<any> {
    return this.http.post('meals/snacks/list', req);
  }

  // ? DIET CATEGORIES
  addDietCategory(req: any): Observable<any> {
    return this.http.post('diet/category/add', req);
  }

  updateDietCategory(req: any): Observable<any> {
    return this.http.post('diet/category/update', req);
  }

  deleteDietCategory(req: any): Observable<any> {
    return this.http.post('diet/category/delete', req);
  }

  getDietCategoryById(req: any): Observable<any> {
    return this.http.post('diet/category', req);
  }

  getDietCategories(req: any): Observable<any> {
    return this.http.post('diet/categories', req);
  }

  getDietCategoriesList(req: any): Observable<any> {
    return this.http.post('diet/categories/list', req);
  }

  // ? DIET TYPES
  addDietType(req: any): Observable<any> {
    return this.http.post('diet/add', req);
  }

  updateDietType(req: any): Observable<any> {
    return this.http.post('diet/update', req);
  }

  deleteDietType(req: any): Observable<any> {
    return this.http.post('diet/delete', req);
  }

  getDietTypeById(req: any): Observable<any> {
    return this.http.post('diet', req);
  }

  getDietTypes(req: any): Observable<any> {
    return this.http.post('diets', req);
  }

  getDietTypesList(req: any): Observable<any> {
    return this.http.post('diets/list', req);
  }

  // ? INGREDIENT CATEGORIES
  addIngredientCategory(req: any): Observable<any> {
    return this.http.post('ingredient/category/add', req);
  }

  updateIngredientCategory(req: any): Observable<any> {
    return this.http.post('ingredient/category/update', req);
  }

  deleteIngredientCategory(req: any): Observable<any> {
    return this.http.post('ingredient/category/delete', req);
  }

  getIngredientCategoryById(req: any): Observable<any> {
    return this.http.post('ingredient/category', req);
  }

  getIngredientCategories(req: any): Observable<any> {
    return this.http.post('ingredient/categories', req);
  }

  getIngredientCategoriesList(req: any): Observable<any> {
    return this.http.post('ingredient/categories/list', req);
  }

  // ? INGREDIENTS
  addIngredient(req: any): Observable<any> {
    return this.http.post('ingredient/add', req);
  }

  updateIngredient(req: any): Observable<any> {
    return this.http.post('ingredient/update', req);
  }

  deleteIngredient(req: any): Observable<any> {
    return this.http.post('ingredient/delete', req);
  }

  getIngredientById(req: any): Observable<any> {
    return this.http.post('ingredient', req);
  }

  getIngredients(req: any): Observable<any> {
    return this.http.post('ingredients', req);
  }

  getIngredientsList(req: any): Observable<any> {
    return this.http.post('ingredients/list', req);
  }

  changeBlacklistStatus(req: any): Observable<any> {
    return this.http.post('ingredient/status/update', req);
  }

  // ? HEATING INSTRUCTIONS
  addHeatingInstruction(req: any): Observable<any> {
    return this.http.post('heating/instruction/add', req);
  }

  updateHeatingInstruction(req: any): Observable<any> {
    return this.http.post('heating/instruction/update', req);
  }

  deleteHeatingInstruction(req: any): Observable<any> {
    return this.http.post('heating/instruction/delete', req);
  }

  getHeatingInstructionById(req: any): Observable<any> {
    return this.http.post('heating/instruction', req);
  }

  getHeatingInstructions(req: any): Observable<any> {
    return this.http.post('heating/instructions', req);
  }

  getHeatingInstructionsList(req: any): Observable<any> {
    return this.http.post('heating/instructions/list', req);
  }

  // ? PLAN DURATIONS
  addPlanDuration(req: any): Observable<any> {
    return this.http.post('plan/duration/add', req);
  }

  updatePlanDuration(req: any): Observable<any> {
    return this.http.post('plan/duration/update', req);
  }

  deletePlanDuration(req: any): Observable<any> {
    return this.http.post('plan/duration/delete', req);
  }

  getPlanDurationById(req: any): Observable<any> {
    return this.http.post('plan/duration', req);
  }

  getPlanDurations(req: any): Observable<any> {
    return this.http.post('plan/durations', req);
  }

  getPlanDurationsList(req: any): Observable<any> {
    return this.http.post('plan/durations/list', req);
  }

  // ? DELIVERY SHIFTS
  addDeliveryShift(req: any): Observable<any> {
    return this.http.post('delivery/shift/add', req);
  }

  updateDeliveryShift(req: any): Observable<any> {
    return this.http.post('delivery/shift/update', req);
  }

  deleteDeliveryShift(req: any): Observable<any> {
    return this.http.post('delivery/shift/delete', req);
  }

  getDeliveryShiftById(req: any): Observable<any> {
    return this.http.post('delivery/shift', req);
  }

  getDeliveryShifts(req: any): Observable<any> {
    return this.http.post('delivery/shifts', req);
  }

  getDeliveryShiftsList(req: any): Observable<any> {
    return this.http.post('delivery/shifts/list', req);
  }

  // ? LOCATION
  // ? GOVERNORATES
  addGovernorate(req: any): Observable<any> {
    return this.http.post('governorate/add', req);
  }

  updateGovernorate(req: any): Observable<any> {
    return this.http.post('governorate/update', req);
  }

  deleteGovernorate(req: any): Observable<any> {
    return this.http.post('governorate/delete', req);
  }

  getGovernorateById(req: any): Observable<any> {
    return this.http.post('governorate', req);
  }

  getGovernorate(req: any): Observable<any> {
    return this.http.post('governorates', req);
  }

  getGovernorateList(req: any): Observable<any> {
    return this.http.post('governorates/list', req);
  }

  // ? AREAS
  addArea(req: any): Observable<any> {
    return this.http.post('area/add', req);
  }

  updateArea(req: any): Observable<any> {
    return this.http.post('area/update', req);
  }

  deleteArea(req: any): Observable<any> {
    return this.http.post('area/delete', req);
  }

  getAreaById(req: any): Observable<any> {
    return this.http.post('area', req);
  }

  getAreas(req: any): Observable<any> {
    return this.http.post('areas', req);
  }

  getAreasList(req: any): Observable<any> {
    return this.http.post('areas/list', req);
  }

  // ? DELIVERY CHARGES
  getDeliveryChargesList(req: any): Observable<any> {
    return this.http.post('delivery/charge/list', req);
  }

  updateDeliveryCharge(req: any): Observable<any> {
    return this.http.post('delivery/charge/update', req);
  }

  // ? SUBSCRIPTION START DATES
  getSubscriptionStartDates(req: any): Observable<any> {
    return this.http.post('setting/subscription/start/date', req);
  }

  updateSubscriptionStartDate(req: any): Observable<any> {
    return this.http.post('setting/subscription/start/date/update', req);
  }

  // ? SUBSCRIPTION FREEZES
  getSubscriptionFreezes(req: any): Observable<any> {
    return this.http.post('setting/subscription/freeze', req);
  }

  updateSubscriptionFreeze(req: any): Observable<any> {
    return this.http.post('setting/subscription/freeze/update', req);
  }

  // ? SUBSCRIPTION CANCELLATION
  getSubscriptionCancellation(req: any): Observable<any> {
    return this.http.post('setting/subscription/cancel', req);
  }

  updateSubscriptionCancellation(req: any): Observable<any> {
    return this.http.post('setting/subscription/cancel/update', req);
  }
}
