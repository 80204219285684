import { Component, Input, inject, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'custom-svg',
  templateUrl: './custom-svg.component.html',
  styleUrls: ['./custom-svg.component.scss']
})
export class CustomSvgComponent {
  // ? INPUT
  @Input() public src = '';

  // ? INJECTS
  http = inject(HttpClient)
  sanitize = inject(DomSanitizer)

  // ? VARIABLES
  svg: SafeHtml = '';

  ngOnInit(): void {
    this.http.get(this.src, { responseType: 'text' }).subscribe(svg => {
      this.svg = this.sanitize.bypassSecurityTrustHtml(svg);
    });
  }
}
