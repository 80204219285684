<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div>
      <div class="dropdown-heading">Notifications</div>
      <div class="dropdown-subheading" *ngIf="notificationsList && notificationsList.length > 0">You have {{
        notificationsList.length }} new notifications.</div>
    </div>

    <custom-svg matTooltip="Mark As Read" class="custom-svg fill-white w-6 h-6"
      *ngIf="notificationsList && notificationsList.length > 0" (click)="markAllAsRead()"
      src="assets/img/actions/mark.svg" />
  </div>

  <div class="dropdown-content" *ngIf="notificationsList && notificationsList.length > 0">
    <a *ngFor="let notification of notificationsList; trackBy: trackById" [class.read]="notification.read"
      (click)="goToEdit(notification.id, notification.option, notification.customerId)"
      class="notification flex items-center cursor-pointer" matRipple>
      <div class="flex-auto">
        <div class="notification-label">{{ notification.content }}</div>
      </div>
    </a>
  </div>

  <div class="py-4 px-6" *ngIf="notificationsList && notificationsList.length === 0">
    No new notifications.
  </div>
</div>