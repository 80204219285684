import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // ? VARIABLES
  isAuthenticated: boolean = false;

  // ? INJECTS
  http = inject(HttpClient)
  router = inject(Router)
  commonService = inject(CommonService)
  messageService = inject(MessageService)

  // ? LOGIN
  login(credentials: any): Observable<any> {
    // ? IF THE USER IS ALREADY LOGGED IN
    if (this.isAuthenticated) {
      // ? SHOW A DIALOG CALLED USER IS ALREADY LOGGED IN.
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'User has already logged in...' });
      return
    }

    return this.http.post('login', { data: this.commonService.encryptData(credentials) }).pipe(
      switchMap((response: any) => {
        this.isAuthenticated = true;
        return of(response);
      })
    )
  }

  // ? LOG OUT
  logOut(): Observable<boolean> {
    localStorage.clear();
    this.isAuthenticated = false;
    this.router.navigateByUrl('/login');
    return of(true);
  }
}
