import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  // ? INJECTS
  http = inject(HttpClient)

  updateProfile(req: any): Observable<any> {
    return this.http.post('user/change/profile', req);
  }
}
