import { AfterViewInit, ChangeDetectorRef, Component, Inject, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/shared/services/common.service';
import { FeedbackService } from '../../services/feedback.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnSubscribe } from 'src/app/shared/classes/un-sub';
import { Feedback } from '../../models/feedback.model';
import { takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-update-feedback',
  templateUrl: './update-feedback.component.html',
  styleUrls: ['./update-feedback.component.scss']
})
export class UpdateFeedbackComponent extends UnSubscribe implements AfterViewInit {
  // ? INJECTS
  feedbackService = inject(FeedbackService);
  commonService = inject(CommonService);
  messageService = inject(MessageService)
  cdr = inject(ChangeDetectorRef)

  // ? MODELS
  feedbackModel: Feedback = new Feedback()

  constructor(public dialogRef: MatDialogRef<UpdateFeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    super()
  }

  ngAfterViewInit(): void {
    if (this.data)
      this.getFeedbackById()

    this.cdr.detectChanges()
  }

  // ? GET THE FEEDBACK DATA BY ITS ID
  getFeedbackById() {
    const req = {
      "id": this.data.id,
      "userId": parseInt(localStorage.getItem('userId'))
    }
    this.feedbackService.getFeedbackById({ data: this.commonService.encryptData(req) }).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const response = this.commonService.decryptData({ data: res })
      this.feedbackModel = response
    })
  }

  updateFeedback() {
    this.feedbackModel.tz = moment.tz.guess();
    this.feedbackModel.userId = parseInt(localStorage.getItem('userId'))
    this.feedbackModel.status = 1
    this.feedbackService.updateFeedback({ data: this.commonService.encryptData(this.feedbackModel) }).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Feedback updated successfully...' });
      this.dialogRef.close(true)
    }, (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong please try again...' });
    })
  }
}
