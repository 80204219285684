
export class GetList {
    search: string = null;
    status: number = null;
    startDate: string = null;
    endDate: string = null;
    offset: number = 0;
    limit: number = 10;
    pageCount: number = null;
    userId: number = parseInt(localStorage.getItem('userId'));
}