<div #originRef (click)="showPopover(originRef)"
  class="flex items-center cursor-pointer relative trans-ease-out select-none">
  <span class="custom-svg">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256.000000 256.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M1116 2549 c-484 -67 -895 -403 -1046 -857 -50 -152 -64 -242 -64
  -412 0 -170 14 -260 64 -412 122 -366 432 -676 798 -798 152 -50 242 -64 412
  -64 170 0 260 14 412 64 366 122 676 432 798 798 50 152 64 242 64 412 0 170
  -14 260 -64 412 -103 307 -345 584 -641 734 -85 42 -217 87 -324 109 -96 20
  -314 28 -409 14z m389 -164 c447 -95 786 -434 880 -880 69 -324 -17 -673 -233
  -943 l-18 -23 -18 58 c-70 223 -236 412 -456 523 -36 18 -66 34 -68 35 -2 1
  26 30 61 64 239 230 214 611 -54 813 -229 173 -550 132 -736 -94 -176 -215
  -158 -526 44 -719 35 -34 63 -63 61 -64 -2 -1 -32 -17 -68 -35 -220 -110 -386
  -301 -456 -523 l-18 -58 -18 23 c-216 270 -302 618 -233 943 72 340 285 620
  591 777 129 66 187 84 394 122 51 9 268 -2 345 -19z m-130 -411 c285 -74 386
  -414 188 -634 -178 -198 -510 -149 -631 93 -106 213 15 478 248 541 72 19 123
  19 195 0z m88 -940 c34 -8 100 -34 147 -57 70 -34 102 -58 181 -136 78 -79
  102 -111 136 -181 40 -82 73 -185 73 -229 0 -14 -21 -36 -66 -69 -120 -89
  -289 -161 -449 -193 -90 -18 -320 -18 -410 0 -161 32 -329 104 -450 193 l-67
  50 6 45 c32 234 228 467 468 554 45 17 107 34 137 39 73 12 217 4 294 -16z" />
      </g>
    </svg>
  </span>
</div>