import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationItemComponent } from './navigation-item.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [NavigationItemComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
  exports: [NavigationItemComponent]
})
export class NavigationItemModule {
}
